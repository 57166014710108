.packageSelector {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  .selectorContainer {
    position: relative;
    padding-left: 28px;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 12px;
      height: 100%;
      background: #0367A5;
    }

    .selector {
      display: flex;
      align-items: center;
      color: #0367A5;
      cursor: pointer;
      margin-bottom: 8px;

      .text {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        margin-right: 12px;
      }

      .icon {
        font-size: 16px;
      }
    }

    .breadContainer {
      display: flex;
      align-items: center;
      
      .qicon {
        padding-left: 12px;
        font-size: 14px;
        color: #0367A5;
      }
    }
  }

  .dateFilter {
    display: flex;
    align-items: flex-start;

    .text {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      margin-right: 12px;
    }

    // .filter {}
  }
}

.switchProjectPopover {
  width: 327px;
  height: 350px;
  padding: 8px 0px;

  display: grid;
  grid-template-rows: auto 1fr;

  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    padding: 8px 16px;
    align-items: center;

    border-bottom: 1px solid #E4E6E7;

    .searchText {
      font-size: 14px;

    }

    .searchContainer {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      justify-content: center;
      // margin-right: 35px;
      width: 100%;
      background-color: rgba(167, 171, 174, 0.2);
      padding: 0px 32px;
      border-radius: 48px;

      .searchInput {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: #393C3E;
        font-size: 14px;

        &::placeholder {
          color: rgba(57, 60, 62, 0.4);
          font-weight: 400;
        }
      }

      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          transform: translateY(1px);
        }
      }
    }
  }

  .customerList {
    height: 100%;
    overflow: auto;

    .customerCard {
      cursor: pointer;

      .name {
        padding: 12px 0px;
        margin: 0px 16px;
        border-bottom: 1px dashed #E4E6E7;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      &:hover {
        background: #F5F5F5;
      }

      &.isActive {
        background: #E6F7FF;

        .name {
          font-weight: 700;
        }
      }

      &:last-child {
        .name {
          border: none;
        }
      }
    }
  }
}