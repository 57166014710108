.resCard {
  margin: 16px 0px;
  padding: 20px 28px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #E4E6E7;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);

  cursor: default;

  .vocTitle {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 700;
    transition: color 0.2s linear;
    cursor: pointer;
    margin-bottom: 4px;
    &:hover {
      color: #0367A5;
    }
  }
  .vocInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 12px;
    .infoItem {
      position: relative;
      padding-right: 4px;
      margin-right: 4px;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
      &::after {
        position: absolute;
        top: 50%;
        right: -0.5px;
        transform: translateY(-45%);
        content: '';
        height: 60%;
        width: 1px;
        background-color: #393C3E;
      }
      &:last-child {
        &::after {
          width: 0px;
        }
      }
    }
  }
  .contentContainer {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    color: rgba(57, 60, 62, 0.6);
    line-height: 1.8;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }
  .footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .postType {
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
      color: rgba(57, 60, 62, 0.4);
    }
    .topicAction {
      display: flex;
      cursor: pointer;
      transition: opacity 0.2s linear;
      .topicTitle {
        margin-left: 8px;
        color: #0367A5;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}