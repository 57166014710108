.pageContainer {
  .segmentContainer {
    display: flex;

    .segmentItem {
      padding: 16px 28px;
      background: rgba(57, 60, 62, 0.04);
      border: 1px solid #E4E6E7;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: rgba(57, 60, 62, 0.6);
      transition: background 0.2s linear, color 0.2s linear;

      &:first-child {
        border-radius: 8px 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 8px 0px 0px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.6);
        color: rgba(3, 103, 165, 0.6);
      }

      &.active {
        position: relative;
        color: #0367A5;
        background: #fff;

        &::after {
          content: '';
          width: 100%;
          height: 4px;

          background: #fff;

          position: absolute;
          bottom: -2px;
          left: 0;

        }
      }
    }
  }

  .resContainer {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0px 8px 8px 8px;

    .actionsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 28px;
      border-bottom: 1px dashed #E4E6E7;
      .filter {
        display: flex;
        column-gap: 8px;
        align-items: center;

        .btn {
          margin-left: 4px;
          width: 120px;

          &.submitBtn {
            color: #0367A5;
            border-color: #0367A5;
            transition: opacity 0.2s linear;

            &:hover {
              opacity: 0.6;
            }
          }

          &.cleanBtn {
            color: #0367A5;
            transition: opacity 0.2s linear;

            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
      .btnContainer {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }
    }
    .chartTitle {
      padding: 28px 20px 0px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
      .tooltip {
        margin-left: 8px;
        color: #0367A5;

        .tooltipText {
          white-space: nowrap;
          width: unset;
        }
      }
    }
    .timeContainer {
      padding: 0px 20px;
    }
  }
}