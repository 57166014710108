.chartContainer {
  position: relative;

  .tabContainer {
    padding: 20px 28px 0px;
  }

  .downloadContainer {
    padding: 20px 28px;

    .chartHeader {
      display: grid;
      grid-template-columns: 1fr auto auto;

      .chartTitle {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.8;

        .tooltip {
          margin-left: 8px;
          color: #0367A5;

          .tooltipText {
            white-space: nowrap;
            width: unset;
          }
        }
      }

      .downloadBtns {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }

      .chartTypeContainer {
        position: relative;
        margin-left: 8px;
        padding-left: 8px;
        display: flex;
        align-items: center;
        column-gap: 8px;

        &::after {
          content: '';
          width: 1px;
          height: 16px;
          background-color: #A7ABAE;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .active {
          border-color: #0367A5;
          background: #0367A5;
          display: flex;
          align-items: center;
          justify-content: center;

          .line {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/lineWhite.svg');
          }

          .bar {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/barWhite.svg');
            transform: rotate(90deg);
          }

          .pie {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/pieWhite.svg');
          }

          .compare {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/barWhite.svg');
          }
        }

        .inactive {
          display: flex;
          align-items: center;
          justify-content: center;

          .line {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/line.svg');
          }

          .bar {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/bar.svg');
            transform: rotate(90deg);
          }

          .pie {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/pie.svg');
          }

          .compare {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../../assets/chartType/bar.svg');
          }

          &:hover {
            border-color: #0367A5;
            background: #0367A5;

            .line {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../../assets/chartType/lineWhite.svg');
            }

            .bar {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../../assets/chartType/barWhite.svg');
              transform: rotate(90deg);
            }

            .pie {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../../assets/chartType/pieWhite.svg');
            }

            .compare {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../../assets/chartType/barWhite.svg');
            }
          }
        }
      }
    }

    .trendTagContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      padding: 10px 0px;

      .trendTag {
        display: flex;
        align-items: center;
        column-gap: 6px;

        .tagColor {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .btnContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    &.isHotWord {
      grid-template-columns: repeat(3, 1fr);
    }

    .btn {
      width: 120px;
      height: 44px;

      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;

        &:hover {
          opacity: 0.8;
        }
      }

      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
    }
  }
}

.compare {
  transform: rotate(-90deg) rotateX(180deg);
}