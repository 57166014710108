.pageContainer {
  position: relative;
  height: 100vh;
  overflow: auto;
  background-color: #F1F5F9;

  .loadingContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2000;

    .curtain {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .loadingIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .contentContainer {
    height: 100%;
    overflow: auto;
    padding: 32px 28px 32px 40px;
    position: relative;

    .titleContainer {
      position: relative;
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 100%;
        background: #0367A5;
      }

      .pageTitle {
        position: relative;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 700;
        color: #0367A5;
        margin-bottom: 8px;
      }
    }

    .searchRes {
      margin: 24px 0px;
      padding: 20px 28px;
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      .resContent {
        font-size: 14px;
        line-height: 1.8;
        font-weight: 400;

        .item {
          position: relative;
          padding-right: 4px;
          margin-right: 4px;

          &::after {
            position: absolute;
            top: 50%;
            right: -0.5px;
            transform: translateY(-40%);
            content: '';
            height: 60%;
            width: 1px;
            background-color: #393C3E;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.8;

      padding-left: 28px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #CBCBCB;
    }

    .actionContainer {
      padding: 20px 0px 16px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sorts {
        display: flex;
        align-items: center;

        .sortText {
          font-size: 14px;
          line-height: 1.6;
          font-weight: 400;
          margin-right: 8px;
        }

        .filterSelect {
          margin-right: 8px;
        }
      }

      .downloadButton {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // .resContainer {
    //   padding-bottom: 20px;
    // }
  }
}