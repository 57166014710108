.chartContainer {
  padding: 0px 24px;
  .chartContent {
    padding: 20px 0px;
    .chartTitle {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
      .tooltip {
        margin-left: 8px;
        color: #0367A5;
        .tooltipText {
          white-space: nowrap;
          width: unset;
        }
      }
    }
  }
}

.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .btnContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    &.speTwo {
      grid-template-columns: repeat(2, 1fr);
    }

    .btn {
      width: 120px;
      height: 44px;

      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;

        &:hover {
          opacity: 0.8;
        }
      }

      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
    }
  }
}