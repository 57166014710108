.container {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  .pieContainer {
    max-width: 600px;
    flex-grow: 1;
  }
}