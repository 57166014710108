.pickerPopover {
  padding: 20px 24px;
  .selectRow {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
  }
  .picker {
    margin-bottom: 20px;
  }
  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 120px;
      height: 44px;
      &.cancel {
        color: #0367A5;
        border-color: #0367A5;
      }
      &.submit {
        background-color: #0367A5;
        border-color: #0367A5;
        color: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.selector {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  column-gap: 6px;
  background: #fff;
  padding: 7px 16px;
  border-radius: 8px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.25);
  row-gap: 8px;
  cursor: pointer;

  &.inactive {
    cursor: not-allowed;
  }
  .currentText {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    .text {
      font-size: 14px;
      line-height: 1;
      color: #393C3E;
    }
  }
  .previousText {
    .text {
      font-size: 9px;
      line-height: 1;
      color: #393C3E;
    }
  }
}