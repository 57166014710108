.pageContainer {
  min-height: 100%;

  .pageTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .textContainer {
      display: flex;
      align-items: center;

      .titlePrefix {
        height: 30px;
        width: 12px;
        background: #0367A5;
        margin-right: 16px;
      }

      .pageTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.2;
        color: #0367A5;
      }
    }

    .backHomeBtn {
      justify-self: flex-end;
      color: #0367A5;
      opacity: 1;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}