.levelItem {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  &.child {
    padding-left: 24px;
  }
  .levelName {
    margin-left: 8px;
  }
}