.pageContainer {
  .logContainer {
    margin: auto 0px 0px;
    font-size: 12px;
    text-align: right;
    line-height: 1.5;
    .title {
      font-weight: 500;
    }
    .error {
      color: #D34C1A;
    }
  }
  .addBtnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;

    .addBtn {
      height: 28px;
      line-height: 18px;
      color: #0367A5;
      border-color: #0367A5;
      transition: color .2s linear, border-color .2s linear;

      &:hover {
        color: rgba(3, 103, 165, 0.6);
        border-color: rgba(3, 103, 165, 0.6);
      }
    }
  }

  .resContainer {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .infoContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 28px;

      .totalCountInfo {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
      }

      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0px;

        .searchInputContainer {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: center;
          justify-content: center;
          width: 300px;
          height: 40px;
          background-color: rgba(167, 171, 174, 0.2);
          padding: 0px 32px;
          border-radius: 48px;
          margin-left: 26px;

          .searchInput {
            width: 100%;
            height: 40px;
            background-color: transparent;
            color: rgb(57, 60, 62);
            font-size: 14px;

            &::placeholder {
              color: rgba(57, 60, 62, 0.4);
              font-weight: 400;
            }
          }

          .searchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              transform: translateY(1px);
            }
          }
        }
      }
    }

    .tableContainer {
      margin: 0px 28px 24px;
      border: 1px solid #E4E6E7;
      border-radius: 8px 8px 0px 0px;

      .headerRow {
        padding: 11px 0px;
        font-size: 12px;
        line-height: 1.8;
        font-weight: 400;
      }

      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        background: #393C3E;
        color: #fff;
        padding: 6px 8px;

        &::before {
          display: none;
        }
      }

      .row {
        position: relative;

        .columns {
          background: unset;
          color: unset;

          &::before {
            display: none;
          }

          .actionBtn {
            width: unset;
            height: unset;
            color: #393C3E;
            margin-right: 6px;

            &:last-child {
              margin-right: 0px;
            }

            &:hover {
              color: #0367A5;
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: #F1F5F9;
        }
      }
    }
  }
}

.deleteModal {
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .deleteText {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      white-space: pre-wrap;
      margin-bottom: 20px;
      text-align: center;
    }

    .btnContainer {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(2, auto);
      grid-gap: 12px;

      .btn {
        width: 120px;
        height: 44px;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;

        &.confirm {
          color: #0367A5;
          border-color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }

        &.cancel {
          &:hover {
            color: #0367A5;
            border-color: #0367A5;
          }
        }
      }
    }
  }
}


.selectModal {
  z-index: 1001;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      white-space: pre-wrap;
      margin-bottom: 20px;
      text-align: center;
    }

    .btnContainer {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(3, auto);
      grid-gap: 12px;

      .btn {
        width: 120px;
        height: 44px;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        &:hover {
          color: #0367A5;
          border-color: #0367A5;
        }

        &.disabled {
          color: rgba(57, 60, 62, 0.4);
          border-color: rgba(57, 60, 62, 0.4);
          opacity: 0.6;
        }

      }
    }
  }
}