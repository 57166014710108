.chartContainer {
  border: 1px dashed #A7ABAE;
  border-radius: 8px;

  .download {
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerTitle {
      margin-left: 16px;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.8;
    }
    .downloadActions {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }

  .timeStamp {
    padding-left: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8;
  }

  .wordsContainer {
    position: relative;
    width: 100%;
    height: 400px;
  }

  .collapseContainer {
    margin-top: 40px;
    font-size: 16px;

    .tableContainer {

      margin: 20px 0px;
      border: 1px solid #E4E6E7;
      border-radius: 8px 8px 0px 0px;

      .headerRow {
        padding: 11px 0px;
        font-size: 12px;
        line-height: 1.8;
        font-weight: 400;
      }

      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        background: #393C3E;
        color: #fff;
        padding: 12px 8px;

        &::before {
          display: none;
        }
      }

      .row {
        position: relative;

        .columns {
          background: unset;
          color: unset;

          &::before {
            display: none;
          }

          .actionBtn {
            width: unset;
            height: unset;
            color: #393C3E;
            margin-right: 6px;

            &:last-child {
              margin-right: 0px;
            }

            &:hover {
              color: #0367A5;
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: #F1F5F9;
        }
      }
    }
  }
}

.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .btnContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    .btn {
      width: 120px;
      height: 44px;

      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;

        &:hover {
          opacity: 0.8;
        }
      }

      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
    }
  }
}

.blockModal {
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    border-bottom: 1px solid #E4E6E7;

    .modalTitle {
      display: flex;
      align-items: center;
      column-gap: 16px;

      .modalText {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .tableContainer {
    padding: 20px;
    height: 450px;
    overflow: scroll;

    .headerRow {
      padding: 11px 0px;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
    }

    .columns {
      white-space: pre-wrap;
      text-align: center;
      font-size: 12px;
      background: #393C3E;
      color: #fff;
      padding: 12px 8px;

      &::before {
        display: none;
      }
    }

    .row {
      position: relative;

      .columns {
        background: unset;
        color: unset;

        &::before {
          display: none;
        }

        .actionBtn {
          width: unset;
          height: unset;
          color: #393C3E;
          margin-right: 6px;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: #0367A5;
          }
        }
      }

      &:nth-child(odd) {
        background: #fff;
      }

      &:nth-child(even) {
        background: #F1F5F9;
      }
    }
  }
}