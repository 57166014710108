.contentContainer {
  background: #FFFFFF;

  border: 1px solid #E4E6E7;

  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  .actionContainer {
    border-bottom: 1px solid #E4E6E7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 28px;

    .filterContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      .filterDateText {
        font-size: 14px;
        line-height: 1.6;
        font-weight: 400;
        margin-right: 12px;
        color: #0367A5;
      }

      .dateSelector {
        background: #F5F5F5;
        border: none;
      }

    }

    .btnContainer {
      display: flex;
      align-items: center;

      .deleteBtn {
        color: #0367A5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .sortBtn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #0367A5;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.6;
    }

    .sortIconContainer {
      display: flex;
      flex-direction: column;
      margin-right: 8px;

      .up {
        transform: translateY(2px);
      }

      .down {
        transform: translateY(-2px);
      }
    }
  }

  .resContainer {
    .tableActionsContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px 28px;

      .rowActions {
        display: grid;
        grid-template-columns: repeat(2, 120px);
        grid-gap: 24px;

        .backBtn {
          width: 100%;
          transition: color 0.2s linear, border-color 0.2s linear;

          &:hover {
            color: #0367A5;
            border-color: #0367A5;
          }
        }

        .cancelBtn {
          width: 100%;
          transition: color 0.2s linear, border-color 0.2s linear;

          &:hover {
            color: #0367A5;
            border-color: #0367A5;
          }

        }
      }

      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0px;

        .searchInputContainer {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: center;
          justify-content: center;
          width: 300px;
          height: 40px;
          background-color: rgba(167, 171, 174, 0.2);
          padding: 0px 32px;
          border-radius: 48px;
          margin-left: 26px;

          .searchInput {
            width: 100%;
            height: 40px;
            background-color: transparent;
            color: rgb(57, 60, 62);
            font-size: 14px;

            &::placeholder {
              color: rgba(57, 60, 62, 0.4);
              font-weight: 400;
            }
          }

          .searchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              transform: translateY(1px);
            }
          }
        }
      }
    }

    .tableContainer {
      margin: 0px 28px 24px;
      border: 1px solid #E4E6E7;
      border-radius: 8px 8px 0px 0px;

      .tableTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .sortIconContainer {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
    
          .up {
            transform: translateY(2px);
          }
    
          .down {
            transform: translateY(-2px);
          }
          .activeIcon {
            opacity: 0.4;
          }
        }
      }

      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        background: #393C3E;
        color: #fff;
        padding: 6px 8px;

        &::before {
          display: none;
        }
      }

      .row {
        position: relative;

        .columns {
          background: unset;
          color: unset;

          &::before {
            display: none;
          }

          .actionBtn {
            position: relative;
            color: #0367A5;
            &::after {
              content: "";
              position: absolute;
              bottom: 6px;
              left: 50%;
              transform: translateX(-50%);
              height: 1px;
              width: 98px;
              background-color: #0367A5;
            }
            
            &:hover {
              opacity: 0.6;
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: #F1F5F9;
        }
      }
    }
  }
}