.drawerContainer {
  .contentContainer {
    height: 100%;
    overflow: scroll;
    padding-left: 40px;
    padding-right: 28px;
    .section {
      border-bottom: 1px solid #D9D9D9;
      &:last-child {
        border-bottom: none;
        padding-bottom: 32px;
      }
      .settingHeader {
        display: grid;
        grid-template-columns: repeat(3, auto) 1fr;
        grid-gap: 10px;
        align-items: center;
        padding: 32px 0px;
        .settingTitle {
          font-size: 20px;
          line-height: 1.2;
          font-weight: 700;
          color: #0367A5;
        }
      }
      .ssoBlock {
        border-bottom: 1px dashed #D9D9D9;
        padding: 12px 0px;
      }
      .vocBlock {
        padding: 32px 0px 12px;
      }
      .ssoRow {
        display: grid;
        grid-template-columns: 1fr 50%;
        grid-gap: 28px;
        padding-bottom: 20px;
        align-items: center;
        .rowTitle {
          text-align: right;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          &.disabledTitle {
            opacity: 0.4;
          }
        }
        .rowContent {
          width: 100%;
          .inputStyle {
            width: 100%;
            height: 44px;
            &.disabledInput {
              background: #fff;
            }
          }
        }
      }
      .vocRow {
        display: grid;
        grid-template-columns: 1fr 50%;
        grid-gap: 28px;
        align-items: center;
        &.errorRow {
          .rowTitle {
            color: #D34C1A;
          }
          .rowContent {
            margin-bottom: 0px;
            .rowInput {
              border-color: #D34C1A;
            }
            .rowPicker {
              border-color: #D34C1A;
            }
            .rowSelect {
              div {
                border-color: #D34C1A;
              }
            }
            .errorNote {
              display: block;
              font-size: 12px;
              line-height: 1;
              padding: 4px 0px;
              color: #D34C1A;
            }
          }
        }
        .rowTitle {
          text-align: right;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          margin-bottom: 20px;
        }
        .rowContent {
          width: 100%;
          margin-bottom: 20px;
          .salesmanContainer {
            display: grid;
            grid-template-columns: 2fr 3fr;
            grid-gap: 10px;
          }
          .rowInput {
            width: 100%;
            height: 44px;
            input {
              height: 44px;
            }
          }
          .rowSelect {
            width: 100%;
            height: 44px;
            div {
              height: 44px;
              span {
                line-height: 44px;
              }
            }
          }
          .rowPicker {
            height: 44px;
          }
          .errorNote {
            display: none;
          }
        }
      }
      .switchSection {
        &.borderBottom {
          border-bottom: 1px dashed #D9D9D9;
        }
        .switchRow {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 0px 28px 0px 0px;
          margin-bottom: 20px;
          .switchItem {
            display: flex;
            align-items: center;
            padding-left: 28px;
  
            .switchTitle {
              margin-left: 12px;
              font-size: 16px;
              line-height: 1;
              font-weight: 400;
            }
          }
        }
      }
      .collapse {
        background-color: #fff;
        padding: 0;
        .collapsePanel {
          border-bottom: 1px dashed #D9D9D9;
          padding: 32px 0px 12px;
          .panelTitle {
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            .panelIcon {
              margin-right: 18px;
            }
            .panelText {
              margin-right: 12px;
              font-size: 16px;
              line-height: 1;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .submitContainer {
    border-top: 1px solid #E4E6E7;
    padding: 28px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .submitBtn {
      width: 160px;
      height: 44px;
    }
  }
  .customizeBack {
    position: absolute;
    top: 0;
    left: -44px;
    width: 44px;
    height: 52px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 0px 0px 4px;

    cursor: pointer;
  }
}