*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Noto Sans TC', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Noto Sans TC', sans-serif !important;
  color: #393C3E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;

  overscroll-behavior: none;
}

input,
textarea {
  font-size: initial;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-break: normal;
  /* anywhere */
}

.nowrap {
  word-wrap: nowrap;
  white-space: nowrap;
}

a {
  color: #393C3E;
  transition: opacity .2s linear;
}

a:hover {
  color: #393C3E;
  opacity: 0.8;
}

input {
  border: 0px solid #000;
  padding: 0px;
}

input:focus-visible {
  outline: 0px;
}

.ant-switch-checked {
  background-color: #0367A5 !important;
}

.ant-collapse-header {
  padding: 0 !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-checkbox-checked>.ant-checkbox-inner {
  background: #0367A5;
}

.ant-checkbox-disabled>.ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.04) !important;
}

/* packagesTable */
.packagesTable .ant-checkbox-checked>.ant-checkbox-inner {
  background: #fff;
  border-color: #A7ABAE;
}

.packagesTable .ant-checkbox-checked>.ant-checkbox-inner::after {
  border-color: #0367A5;
  border-width: 2px;
}

.packagesTable .ant-checkbox-indeterminate>.ant-checkbox-inner::after {
  background-color: #0367A5;
}

.packagesTable thead .ant-table-selection-column {
  background-color: #393C3E !important;
}

.packagesTable .ant-table-cell-row-hover {
  background: unset !important;
}

.packagesTable .ant-table-row-selected>td {
  background: unset !important;
}

/* resTable */

.resTable .ant-checkbox-checked>.ant-checkbox-inner {
  background: #fff;
  border-color: #A7ABAE;
}

.resTable .ant-checkbox-checked>.ant-checkbox-inner::after {
  border-color: #A7ABAE;
  border-width: 1px;
}

.resTable .ant-checkbox-indeterminate>.ant-checkbox-inner::after {
  background-color: #A7ABAE;
}

.resTable thead {
  height: 55px;
}

.resTable thead .ant-table-selection-column {
  background-color: #393C3E !important;
}

.resTable .ant-table-cell-row-hover {
  background: unset !important;
}

.resTable .ant-table-row-selected>td {
  background: unset !important;
}

/* selectModal */

.selectModal>.ant-modal-content {
  padding: 0;
}

/* header */

.header .ant-breadcrumb li .ant-breadcrumb-separator {
  color: rgba(255, 255, 255, 0.45);
}

.header .ant-breadcrumb li:last-child {
  color: #fff;
}

/* TopicSort */

.TopicSort .sortSelect .ant-select-selector {
  background: #fff !important;
}


.select .ant-select-selector {
  background-color: #F5F5F5 !important;
  border: none !important;
  height: 28px !important;
  display: flex;
  align-items: center;
}

/* homeTable */

.homeTable .ant-picker-input input {
  font-size: 12px;
  text-align: center;
}

.homeTable textarea {
  font-size: 12px;
}

.homeTable input {
  font-size: 12px;
}

.homeTable .ant-checkbox-checked>.ant-checkbox-inner {
  background: #fff;
  border-color: #A7ABAE;
}

.homeTable .ant-checkbox-checked>.ant-checkbox-inner::after {
  border-color: #A7ABAE;
  border-width: 1px;
}

.homeTable .ant-checkbox-indeterminate>.ant-checkbox-inner::after {
  background-color: #A7ABAE;
}

/* bulletinDrawer */

.chartTypeRadio .ant-radio-button-wrapper:hover {
  color: #0367A5;
}

.chartTypeRadio .ant-radio-button-wrapper-checked {
  color: #0367A5;
  border-color: #0367A5 !important;
}

.chartTabs .ant-tabs-tab {
  padding: 12px 20px;
  margin: 0px !important;
}

.relatedTabs .ant-tabs-tab {
  padding: 12px 60px;
  margin: 0px !important;
}

.inactiveWord {
  opacity: 0.2;
}

.wordsCollapse .ant-collapse-header {
  justify-content: flex-end;
  align-items: center !important;
}

.wordsCollapse .ant-collapse-header .ant-collapse-header-text {
  margin-inline-end: unset !important;
  flex: unset !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-picker-cell-inner::before {
  border: none !important;
}

.rd3t-svg {
  pointer-events: none;
}

.rd3t-svg path {
  stroke: #A7ABAE;
  stroke-width: 2
}

.rd3t-svg .branchNode {
  stroke-width: 2;
  stroke: #A7ABAE;
}

.ant-tooltip {
  max-width: 500px !important;
}