.pickerPopover {
  padding: 20px 24px;
  .selectRow {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
  }
  .picker {
    margin-bottom: 20px;
  }
  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 120px;
      height: 44px;
      &.cancel {
        color: #0367A5;
        border-color: #0367A5;
      }
      &.submit {
        background-color: #0367A5;
        border-color: #0367A5;
        color: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.selector {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  background: #f5f5f5;
  padding: 0px 11px;
  border-radius: 6px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &.inactive {
    cursor: not-allowed;
  }

  .text {
    line-height: 30px;
    color: #393C3E;
  }
}