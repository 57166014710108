@mixin medium {
  @media(max-width:1199px) {
    @content;
  }
}

@mixin small {
  @media(max-width:767px) {
    @content;
  }
}

.pageContainer {
  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .pageTitle {
      position: relative;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 700;
      color: #0367A5;
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 100%;
        background: #0367A5;
      }
    }

    .announcementSettingBtn {
      color: #0367A5;
      border-color: #0367A5;
      transition: opacity 0.2s linear;
      width: 120px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .announcementContainer {
    margin-bottom: 30px;

    .announcementItem {
      // ? https://css-tricks.com/stripes-css/
      background-image: url('../../assets/HomeIcons/bg-pattern.png');
      border-radius: 8px;
      margin-bottom: 24px;
      position: relative;

      .contentContainer {
        display: flex;
        align-items: center;
        padding: 12px 25px;
        background: #FFFFFF;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: min-content;
        max-width: 100%;
        cursor: pointer;

        .icon {
          margin-right: 5px;
        }

        .text {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.5;
        }

        &:hover {
          .text {
            color: #0367A5;
          }
        }
      }

      &::after {
        content: '';

        position: absolute;
        bottom: -24px;
        left: 37px;
        width: 1px;
        height: 24px;
        background: #A7ABAE;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      margin-right: 17px;
    }

    .titleText {
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
    }
  }

  .articleContainer {
    background: #FFFFFF;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px 28px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;

    .cardContainer {
      padding-bottom: 16px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 16px;
      overflow: auto;
      .card {
        min-width: 180px;
        .thumbnailContainer {
          position: relative;
          width: 100%;
          padding-top: 66%;
          margin-bottom: 16px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .date {
          font-size: 16px;
          line-height: 1;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .text {
          font-weight: 400;
          line-height: 1.8;
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;

        }
      }
    }

    .moreBtn {
      align-self: flex-end;
      font-size: 14px;
      font-weight: 400;
      color: #0367A5;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .sourceContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 24px;
    margin-bottom: 30px;

    @include medium {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    @include small {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
    .skeletonContainer {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 28.5px 16px 20.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .avatar {
        width: 56px;
        height: 56px;
      }
    }

    .sourceItem {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 28.5px 0px 20.5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-bottom: 10px;

        &.news {
          background: #45C0B2;
        }

        &.fb {
          background: #0367A5;
        }

        &.ig {
          background: #D34C1A;
        }

        &.threads {
          background: #393C3E;
          img {
            width: 20px;
          }
        }

        &.videos {
          background: #F2CE16;
        }

        &.forum {
          background: #7CB305;
        }

        &.others {
          background: #D9AC83;
        }
      }

      .sourceCount {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .sourceType {
        font-size: 12px;
        line-height: 1.6;
      }
    }
  }

  .serviceTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .serviceTitle {
      display: flex;
      align-items: center;

      img {
        margin-right: 17px;
      }

      .titleText {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
      }
    }

    .serviceSettingBtn {
      color: #0367A5;
      border-color: #0367A5;
      transition: opacity 0.2s linear;
      width: 120px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .serviceContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px 16px;

    @include medium {
      grid-template-columns: repeat(2, 1fr);
    }

    .serviceItem {
      .videoContainer {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        .video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .itemTitle {
        font-weight: 400;
        line-height: 1.6;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

      }
    }
  }
}

.drawerContainer {
  .contentContainer {
    position: relative;
    padding: 32px 28px 0px 40px;
    display: flex;
    flex-direction: column;

    .titleContainer {
      margin-bottom: 32px;
      display: flex;
      align-items: center;

      .text {
        margin-left: 12px;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        color: #0367A5;
      }
    }

    .addBtn {
      width: 120px;
      align-self: flex-end;
      color: #0367A5;
      border-color: #0367A5;
      transition: opacity 0.2s linear;
      margin-bottom: 20px;

      &:hover {
        opacity: 0.6;
      }
    }

    .tableContainer {
      border: 1px solid #E4E6E7;
      border-radius: 8px;
      overflow: hidden;

      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        border-radius: 0px !important;
        background: #393C3E;
        color: #fff;
        padding: 6px 8px;

        &::before {
          display: none;
        }
        .textContent {
          line-height: 2;
        }
      }

      .headerRow {
        padding: 10px 0px;
      }

      .actionRow {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .editBtn {
          cursor: pointer;
        }
        .deleteBtn {
          cursor: pointer;
        }
      }

      .row {
        position: relative;

        .columns {
          background: unset;
          color: unset;

          &::before {
            display: none;
          }

          .actionBtn {
            color: #393C3E;

            &:hover {
              color: #0367A5;
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: #F1F5F9;
        }
      }

      .dateText {
        padding: 5px 0px;
      }

      .textContainer {
        padding: 4.8px 12px;
        text-align: left;
        line-height: 2;
      }
      .tableInput {
        line-height: 2;
      }
    }
  }

  .customizeBack {
    position: absolute;
    top: 0;
    left: -44px;
    width: 44px;
    height: 52px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 0px 0px 4px;

    cursor: pointer;
  }
}

.deleteModal {
  .deleteContent {
    height: 260px;
    padding: 40px 28px;
    row-gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .deleteText {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;
      .confirmBtn {
        width: 120px;
        height: 44px;
      }
      .cancelBtn {
        width: 120px;
        height: 44px;
      }
    }
  }
}