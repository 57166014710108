.tabContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  overflow: scroll;

  .mainText {
    font-weight: 700;
  }
}

.textContainer {
  pointer-events: auto;
  user-select: none;
  white-space: nowrap;

  width: max-content;
  padding: 6px 10px;

  &.parent {
    color: transparent;
    font-size: 20px;
    font-weight: 900;
    transform: translate(0%, -50% - 5);
  }


  &.first {
    color: transparent;
    font-size: 20px;
    font-weight: 600;
    transform: translate(0%, -50% - 6);
  }

  &.node {
    color: transparent;
    font-size: 20px;
    transform: translate(0%, -50% - 6);
  }
}

.parentText {
  fill: #393C3E;
  font-size: 20px;
  font-weight: 900;
  stroke: none;
  display: inline-flex;
  cursor: pointer;

  :hover {
    fill: 'red'
  }
}

.firstText {
  fill: #393C3E;
  font-size: 20px;
  font-weight: 600;
  stroke: none;
  display: inline-flex;
  cursor: pointer;
}

.nodeText {
  fill: #393C3E;
  font-size: 20px;
  font-weight: 600;
  stroke: none;
  display: inline-flex;
  cursor: pointer;
}

.modalContainer {
  padding: 40px 20px;
  .title {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
}