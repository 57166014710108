.groupContainer {
  .groupHead {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .groupName {
      padding-left: 8px;
    }
  }
  .childrenContainer {
    padding-left: 24px;
  }
}