.switchProjectPopover {
  width: 327px;
  height: 350px;
  padding: 8px 0px;

  display: grid;
  grid-template-rows: auto 1fr;
  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    padding: 8px 16px;
    align-items: center;

    border-bottom: 1px solid #E4E6E7;

    .searchText {
      font-size: 14px;

    }

    .searchContainer {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      justify-content: center;
      // margin-right: 35px;
      width: 100%;
      background-color: rgba(167, 171, 174, 0.2);
      padding: 0px 32px;
      border-radius: 48px;

      .searchInput {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: #393C3E;
        font-size: 14px;

        &::placeholder {
          color: rgba(57, 60, 62, 0.4);
          font-weight: 400;
        }
      }

      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          transform: translateY(1px);
        }
      }
    }
  }

  .customerList {
    height: 100%;
    overflow: auto;

    .customerCard {
      cursor: pointer;

      .name {
        padding: 12px 0px;
        margin: 0px 16px;
        border-bottom: 1px dashed #E4E6E7;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      &:hover {
        background: #F5F5F5;
      }

      &.isActive {
        background: #E6F7FF;
        .name {
          font-weight: 700;
        }
      }
      &:last-child {
        .name {
          border: none;
        }
      }
    }
  }
}

.floatingScrollTo {
  position: absolute;
  right: 19px;
  bottom: 108px;

  width: 72px;
  height: 72px;

  border-radius: 50%;

  background-color: #fff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));

  cursor: pointer;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    font-size: 36px;
    color: #A7ABAE;
    transition: color 0.5s linear;
    &:hover {
      color: #0367A5;
    }
  }
}