.MenuMain {
  .ant-menu-item {
    padding: 10px 18px !important;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    height: 60px;

    .ant-menu-title-content {
      font-weight: 400;
      font-size: 16px;
      font-family: 'Noto Sans Tc', serif;
      margin-inline-start: 25px !important;
      line-height: 1.8;
    }

    &:active {
      background: #fff;
    }

    &.ant-menu-item-selected {
      background-color: rgba(57, 60, 62, 0.92);
      color: #fff;
    }
  }

  .ant-menu-sub {
    background: #fff !important;
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding: 10px 18px !important;
      width: 100%;
      border-radius: 0px;
      margin: 0px;
      height: 60px;

      .ant-menu-title-content {
        font-weight: 400;
        font-size: 16px;
        font-family: 'Noto Sans Tc', serif;
        margin-inline-start: 25px !important;
        line-height: 1.8;
      }
    }

    .ant-menu-item {
      .ant-menu-title-content {
        font-weight: 400;
        font-size: 14px;
        font-family: 'Noto Sans Tc', serif;
        margin-inline-start: 45px !important;
        line-height: 1.8;
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #393C3E;
    }
  }

  .ant-menu-item-divider {
    margin: 0px 8px;
    border: 1px solid #E4E6E7;
    border-bottom: 0px;
  }
}

.ant-popover-inner {
  padding: 0px !important;
}

.sortSelect {
  .ant-select-selector {
    background-color: #F5F5F5 !important;
    border: none !important;
    height: 28px !important;
    display: flex;
    align-items: center;
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    .ant-menu-item {
      margin: 0px;
      padding: 6px 12px;
      height: unset;
      width: 100%;

      &.ant-menu-item-selected {
        background-color: #393C3E;
        border-radius: 0px;
        color: #fff;
      }
    }
  }
}

.ant-menu-inline-collapsed {
  .ant-menu-submenu {
    &.ant-menu-submenu-selected {
      background-color: #393C3E;
      border-radius: 0px;
    }
  }
}

#wordsContainerpresent, #wordsContainerprevious,
#wordsContainer, #presentIndustryWord,
#presentIndustryWordTop1, #presentIndustryWordTop2,
#presentIndustryWordTop3, #previousIndustryWord,
#previousIndustryWordTop1, #previousIndustryWordTop2,
#previousIndustryWordTop3, #brandWordTop, #brandWordMain,
#SeriesWordtop1, #SeriesWordtop2, #SeriesWordtop3,
#BrandFeatureWordtop1, #BrandFeatureWordtop2, #BrandFeatureWordtop3,
#IndustryFeatureWordtop1, #IndustryFeatureWordtop2, #IndustryFeatureWordtop3,
#presentBrandWord, #previousBrandWord, #BrandSentimentWord, #brandWordPNTop, #brandWordPNMain,
#wordsContainerOpinion {
  .words-item {
    left: 0px;
    top: 0px;
    opacity: 1;
    transition: left 1s linear, top 1s linear, opacity .5s linear;
    cursor: pointer;

    .tooltip {
      display: none;
      height: auto;
      line-height: 16px;
      position: absolute;
      border: 2px solid #0367A5;
      border-radius: 4px;
      background-color: #fff;
      font-size: 12px;
      color: #0367A5;
      padding: 4px 8px;

      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:has(> .words-item-hover) {
    .words-item {
      opacity: 0.2;
    }

    .words-item-hover {
      opacity: 1;

      .tooltip {
        display: block;
      }
      .closeBtn {
        display: block;
      }
    }
  }
}