.container {
  position: absolute;
  right: 19px;
  bottom: 18px;

  width: 72px;
  height: 72px;

  border-radius: 50%;

  background-color: #fff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));

  cursor: pointer;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 36px;
    height: 36px;
  }
}