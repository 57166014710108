.switchProjectPopover {
  width: 327px;
  height: 350px;
  padding: 8px 0px;

  display: grid;
  grid-template-rows: auto 1fr;
  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    padding: 8px 16px;
    align-items: center;

    border-bottom: 1px solid #E4E6E7;

    .searchText {
      font-size: 14px;

    }

    .searchContainer {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      justify-content: center;
      // margin-right: 35px;
      width: 100%;
      background-color: rgba(167, 171, 174, 0.2);
      padding: 0px 32px;
      border-radius: 48px;

      .searchInput {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: #393C3E;
        font-size: 14px;

        &::placeholder {
          color: rgba(57, 60, 62, 0.4);
          font-weight: 400;
        }
      }

      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          transform: translateY(1px);
        }
      }
    }
  }

  .customerList {
    height: 100%;
    overflow: auto;

    .customerCard {
      cursor: pointer;

      .name {
        padding: 12px 0px;
        margin: 0px 16px;
        border-bottom: 1px dashed #E4E6E7;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      &:hover {
        background: #F5F5F5;
      }

      &.isActive {
        background: #E6F7FF;
        .name {
          font-weight: 700;
        }
      }
      &:last-child {
        .name {
          border: none;
        }
      }
    }
  }
}

.selector {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  background: #f5f5f5;
  padding: 0px 11px;
  border-radius: 6px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  
  &.inactive {
    cursor: not-allowed;
  }

  .text {
    line-height: 30px;
    &.active {
      color: #393C3E;
    }
  }
}