.pageContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #083D5E 40.1%, #165A84 100%);

  .mainText {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;

    color: #fff;
  }

  .desc {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;

    color: #fff;
    text-align: center;
  }
}