.bulletinItem {
  width: 100%;
  border: 1px dashed #A7ABAE;
  border-radius: 8px;
  padding: 20px 28px;
  margin-bottom: 24px;
  &.errorItem {
    border-color: #D34C1A;
  }

  .nameContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 8px;
    align-items: center;

    .nameContent {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
    }

    .actionContainer {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 8px;
      padding-bottom: 4px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #E4E6E7;
    margin: 20px 0px;
  }

  .selectorContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 28px;
    margin-bottom: 20px;

    .selectorBtn {
      padding: 14px 0px;
      height: 44px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      color: #0367A5;
      border-color: #0367A5;
      transition: opacity 0.2s linear;
    }
  }

  .selectedContentContainer {
    .selectedTitle {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .selectedContent {
      min-height: 120px;
      background: #FFFFFF;
      border: 1px solid #A7ABAE;
      border-radius: 8px;
      padding: 12px 16px;
      line-height: 1.5;
    }
  }
}