.chartContainer {
  padding: 0px 24px;
  .chartHeader {
    display: grid;
    grid-template-columns: 1fr auto auto;
    margin-bottom: 20px;
    .chartTitle {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
    }
    .downloadBtns {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
    .chartTypeContainer {
      position: relative;
      margin-left: 8px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      &::after {
        content: '';
        width: 1px;
        height: 16px;
        background-color: #A7ABAE;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .active {
        border-color: #0367A5;
        color: #0367A5;
      }
    }
  }
}

.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .btnContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    .btn {
      width: 120px;
      height: 44px;
      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;
        &:hover {
          opacity: 0.8;
        }
      }
      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
    }
  }
}