.drawerContainer {
  .contentContainer {
    height: 100%;
    overflow: scroll;
    padding: 0px 28px 20px 40px;

    .contentHeader {
      display: grid;
      grid-template-columns: repeat(3, auto) 1fr;
      grid-gap: 10px;
      align-items: center;
      padding: 32px 0px;

      .headerTitle {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        color: #0367A5;
      }
    }

    .section {
      border-bottom: 1px solid #D9D9D9;

      &.levelSelectSection {
        padding: 20px 0px;
        .errorNote {
          font-size: 12px;
          line-height: 1;
          padding: 4px 0px;
          color: #D34C1A;
        }

        .sectionHeader {
          font-size: 16px;
          line-height: 1.8;
          margin-bottom: 20px;
          &.errorHeader {
            color: #D34C1A;
          }
        }
      }

      .row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 28px;

        align-items: center;
        justify-content: flex-start;

        .rowTitle {
          text-align: right;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 400;
          margin-bottom: 20px;
          &.errorTitle {
            color: #D34C1A;
          }
        }

        .rowContent {
          width: 100%;
          .rowSelect {
            height: 44px;
            width: 100%;
            margin-bottom: 20px;
            &.errorSelect {
              margin-bottom: 0px;
              div {
                border-color: #D34C1A;
              }
            }
            &.errorSelectMargin {
              div {
                border-color: #D34C1A;
              }
            }
            &.multi {
              height: unset;
              div {
                span {
                  height: 36px;
                  line-height: 36px;
                }
                input {
                  min-height: 44px;
                }
              }
            }
            &.single {
              div {
                height: 44px;
                span {
                  line-height: 44px;
                }
                input {
                  height: 44px;
                }
              }
            }
          }
          .rowInput {
            height: 44px;
            margin-bottom: 20px;
            &.errorInput {
              border-color: #D34C1A;
              margin-bottom: 0px;
            }
          }
          .rowPicker {
            height: 44px;
            width: 100%;
            margin-bottom: 20px;
            &.errorPicker {
              border-color: #D34C1A;
              margin-bottom: 0px;
            }
          }
          .link {
            margin-bottom: 20px;
          }
          .errorNote {
            font-size: 12px;
            line-height: 1;
            padding: 4px 0px;
            // line-height: 20px;
            color: #D34C1A;
          }
        }

        .salesContainer {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 8px;
        }

        .projectIDContainer {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 12px;
          align-items: center;
          .projectSelectContainer {
            overflow: hidden;
          }

          .linkIcon {
            opacity: 0.4;
            cursor: pointer;
          }
        }

        .checkboxContainer {
          display: grid;
          grid-template-columns: repeat(4, auto) 1fr;
          margin-bottom: 20px;
          &.errorCheckboxContainer {
            margin-bottom: 0px;
            .checkItem {
              color: #D34C1A;
            }
          }

          .checkItem {
            font-size: 14px;
            line-height: 1.8;
            font-weight: 400;
          }
        }

        &.levelsContainer {
          margin: 20px 0px 0px;
        }

        &.wordbookContainer {
          &:first-child {
            margin-top: 20px;
          }
        }
      }

      .selectBtn {
        margin-bottom: 20px;
        color: #0367A5;
        border-color: #0367A5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.6;
        }
      }

      .selectedContent {
        background: #FFFFFF;

        border: 1px dashed #A7ABAE;
        border-radius: 8px;
        padding: 20px 28px;
        .selectedRow {
          display: grid;
          grid-template-columns: auto 1fr;
          .selectedItem {
            font-size: 14px;
            line-height: 1.8;
            font-weight: 400;
          }
        }
        
      }
    }
  }

  .submitContainer {
    border-top: 1px solid #E4E6E7;
    padding: 13px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .submitBtn {
      height: 44px;
    }
  }

  .customizeBack {
    position: absolute;
    top: 0;
    left: -44px;
    width: 44px;
    height: 52px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 0px 0px 4px;

    cursor: pointer;
  }
}

.tooltipWrap {
  white-space: pre-wrap;
}

.tooltipNoWrap {
  white-space: nowrap;
}