.pageContainer {
  .sectionContainer {
    .segmentContainer {
      display: flex;

      .segmentItem {
        padding: 16px 28px;
        background: rgba(57, 60, 62, 0.04);
        border: 1px solid #E4E6E7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(57, 60, 62, 0.6);
        transition: background 0.2s linear, color 0.2s linear;

        &:first-child {
          border-radius: 8px 0px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 8px 0px 0px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.6);
          color: rgba(3, 103, 165, 0.6);
        }

        &.active {
          position: relative;
          color: #0367A5;
          background: #fff;

          &::after {
            content: '';
            width: 100%;
            height: 4px;

            background: #fff;

            position: absolute;
            bottom: -2px;
            left: 0;
            z-index: 1;
          }
        }
      }
    }

    .settingContainer {
      .settingContent {
        background: #FFFFFF;
        border: 1px solid #E4E6E7;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
        border-radius: 0px 8px 8px 8px;

        .settingTitle {
          padding: 18px 28px;
          border-bottom: 1px solid #E4E6E7;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.8;
        }

        .dateContainer {
          padding: 20px 0px;
          margin: 0px 20px;
          border-bottom: 1px dashed #E4E6E7;

          .itemTitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.8;
            margin-bottom: 20px;
          }
        }

        .searchItem {
          padding: 20px 0px;
          margin: 0px 20px;
          border-bottom: 1px dashed #E4E6E7;


          .itemHeader {
            display: flex;
            align-items: center;

            .itemTitle {
              font-size: 14px;
              font-weight: 400;
              line-height: 1.8;
              margin-bottom: 20px;
            }
          }

          .selectButton {
            margin-bottom: 20px;
            width: 120px;
            color: #0367A5;
            border-color: #0367A5;

            &:hover {
              opacity: 0.6;
            }
          }

          .preview {
            min-height: 120px;
            border: 1px solid #A7ABAE;
            border-radius: 8px;
            padding: 12px;
            white-space: pre-wrap;
            line-height: 1.2;

            &.unselected {
              opacity: 0.6;
            }
          }
        }

        .selectRow {
          margin: 20px;
          display: grid;
          grid-template-columns: 160px 1fr;
          align-items: center;

          &.border {
            border-bottom: 1px dashed #E4E6E7;
            margin: 0px 20px;
            padding: 20px 0px;
          }

          .select {
            width: 220px;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .actions {
        padding: 20px;
        margin: 24px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;

        .actionBtn {
          width: 160px;
          height: 44px;

          &.submitBtn {
            background-color: #0367A5;
          }
        }
      }
      .progressContainer {
        margin-top: 100px;
        .barContainer {
          display: flex;
          align-items: center;
          padding: 0px 48px;
          div {
            margin-bottom: 0px;
          }
          .stopBtn {
            width: 160px;
            height: 44px;
          }
        }
        .infoText  {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        }
      }
    }
  }

  .collapse {
    background: #fff;
    border-radius: 0px 8px 8px 8px;
    border-color: #E4E6E7;

    div {
      &:last-child {
        // border-radius: 0px !important;
        border-color: #E4E6E7;
      }
    }

    .collapseHeader {
      padding: 20px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
    }

    .resSetting {
      .setting {
        padding: 20px 28px;

        .settingRow {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.8;
        }
      }
    }
  }

  .filter {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    border-top: none;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
    padding: 20px 28px;
    display: flex;
    column-gap: 8px;
    align-items: center;

    .btn {
      margin-left: 4px;
      width: 120px;

      &.submitBtn {
        color: #0367A5;
        border-color: #0367A5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.6;
        }
      }

      &.cleanBtn {
        color: #0367A5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .resultContainer {
    margin-top: 20px;
    padding: 20px 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .chartContainer {
      margin-bottom: 20px;
      padding: 20px 24px;
      border: 1px solid #E4E6E7;
      border-radius: 8px;
      &:last-child {
        margin-bottom: 0px;
      }

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.8;
          .tooltip {
            margin-left: 8px;
            color: #0367A5;

            .tooltipText {
              white-space: nowrap;
              width: unset;
            }
          }
        }

        &.border {
          border-bottom: 1px dashed #CBCBCB;
        }

        .chartFilter {
          display: flex;
          column-gap: 8px;
          align-items: center;

          .filterItem {
            display: flex;
            column-gap: 8px;
            align-items: center;

            .itemTitle {
              font-size: 12px;
            }
          }

          .btn {
            margin-left: 4px;
            width: 120px;

            &.submitBtn {
              color: #0367A5;
              border-color: #0367A5;
              transition: opacity 0.2s linear;

              &:hover {
                opacity: 0.6;
              }
            }

            &.cleanBtn {
              color: #0367A5;
              transition: opacity 0.2s linear;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }

        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 4px;
        }
      }

      .radioContainer {
        margin-bottom: 20px;

        .levelsRadio {
          label {
            height: unset;
            padding: 2.5px 16px;

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .emptyContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    height: 600px;
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0px 8px 8px 8px;
    
  }
}