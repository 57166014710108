.modalContainer {
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #D9D9D9;

    .headerLeft {
      display: flex;
      align-items: center;
      column-gap: 18px;

      .headerText {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
      }
    }
  }

  .modalBody {
    padding: 20px 24px;

    .rowItem {
      display: grid;
      grid-template-columns: 3fr 7fr;
      grid-gap: 28px;
      align-items: center;

      &.errorItem {
        .rowTitle {
          color: #D34C1A;
        }

        .rowContent {
          .rowInput {
            margin-bottom: 0px;
            border-color: #D34C1A;
          }
          .errorNote {
            display: block;
            font-size: 12px;
            line-height: 1;
            padding: 4px 0px;
            color: #D34C1A;
          }
        }
      }

      .rowTitle {
        text-align: right;
        position: relative;
        margin-bottom: 20px;
      }

      .rowContent {
        width: 100%;

        .rowSelect {
          width: 100%;
          height: 44px;
          margin-bottom: 20px;

          div {
            height: 44px;

            span {
              line-height: 44px;
            }

            input {
              height: 44px;
            }
          }
        }

        .rowInput {
          width: 100%;
          height: 44px;
          margin-bottom: 20px;
        }
        .errorNote {
          display: none;
        }
      }
    }
  }

  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;

    .btn {
      width: 160px;
      height: 44px;

      &.actionBtn {
        background-color: #0367A5;
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}