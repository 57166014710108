.pageContainer {
  .contentContainer {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .filterContainer {
      display: flex;
      align-items: center;
      padding: 20px 28px;
      column-gap: 12px;
      border-bottom: 1px solid #E4E6E7;

      .btn {
        width: 120px;
        height: 28px;
        padding: 2px 15px;

        &.searchBtn {
          color: #0367A5;
          border-color: #0367A5;
        }

        &.cleanBtn {
          color: #0367A5;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .resContainer {
      .actionRow {
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .scrollToSelect {
          display: flex;
          align-items: center;
          column-gap: 12px;
        }

        .actionContainer {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }
      }

      .chartsContainer {
        padding: 0px 28px 20px;

        .chartItem {
          height: 400px;
          background: #FFFFFF;

          border: 1px dashed #A7ABAE;
          border-radius: 8px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .btnContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;

    &.isHotWord {
      grid-template-columns: repeat(3, 1fr);
    }

    .btn {
      width: 120px;
      height: 44px;

      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;

        &:hover {
          opacity: 0.8;
        }
      }

      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
    }
  }
}