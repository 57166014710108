.container {
  .chartContainer {
    width: 100%;
    display: flex;
    aspect-ratio: 3;
  }
  .tagContainer {
    padding: 20px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    .tag {
      display: flex;
      align-items: center;
      column-gap: 4px;
      cursor: pointer;
      .tagColorBlock {
        width: 40px;
        height: 10px;
      }
      .tagText {
        position: relative;
        font-size: 12px;
        &.block {
          &::after {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            background-color: #393C3E;;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}