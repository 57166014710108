.container {
  position: relative;
  // min-width: 200px;

  .wordsContainer {
    position: relative;
    width: 100%;
    height: 300px;

    .wordItem {
      transition: 1.4s;
      cursor: pointer;
      position: relative;
      width: max-content !important;
      font-family: 'Noto sans TC' !important;

      .tooltip {
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        font-size: 12px;
        color: #393C3E;
        padding: 8px 16px;
        background: #FFFFFF;
        border: 2px solid #0367A5;
        border-radius: 4px;
        color: #0367A5;
        font-weight: 400;
      }

      .closeBtn {
        display: none;
        opacity: 0;
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 12px;
        padding: 9px;
        border-radius: 50%;
        color: #FFFFFF;
        background-color: #0367A5;
        transition: opacity .5s linear;
      }

      &:hover {
        z-index: 10000;

        .tooltip {
          display: block;
        }

        .closeBtn {
          display: block;
          opacity: 1;
        }
      }
    }
  }
}