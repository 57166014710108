.chartItem {
  position: relative;
  min-height: 400px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;

  .chartContainer {
    padding: 0px 13px 20px 20px;
  }
  .curtain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .color {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }

    .absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}