.postInfo {
  position: relative;
  .postTypeContainer {
    position: relative;
    border-radius: 100px;
    font-size: 12px;
    line-height: 1.8;
    font-weight: 400;
    color: #fff;
    overflow: hidden;
    .allow {
      cursor: pointer;
    }

    &.inProcess {
      pointer-events: none;
    }

    &:hover {
      .nextTypeCard {
        transform: translateX(calc(100% - 12px));
      }
    }

    .postTypeCard {
      padding: 4px 16px;
      border-radius: 100px;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);

      &.positive {
        background: #33A99B;
      }

      &.neutral {
        background: #E2BD00;
      }

      &.negative {
        background: #BD4215;
      }
    }

    .nextTypeCard {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px 16px 4px 28px;
      border-radius: 100px;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);
      transform: translateX(calc(100% + 12px));

      &.positive {
        background: #33A99B;
      }

      &.neutral {
        background: #E2BD00;
      }

      &.negative {
        background: #BD4215;
      }

      &.animation {
        transform: translateX(-12px);
        transition: transform 0.3s linear;
      }
      &.notProcess {
        transition: transform 0.2s linear;
      }

      .nextTypeCardText {
        white-space: nowrap;
      }

      .arrow {
        position: absolute;
        left: -12px;
        border-radius: 9px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;

        &.positive {
          background: #33A99B;
        }

        &.neutral {
          background: #E2BD00;
        }

        &.negative {
          background: #BD4215;
        }
      }

    }
  }
  .postMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: not-allowed;
  }
}