.container {
  z-index: 1000;
  position: relative;

  .titleContainer {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #d9d9d9;

    .leftContainer {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 17px;

      .title {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .rightContainer {
      flex: 0;

      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      transition: color 0.3s linear;

      &:hover {
        color: #F1F5F9;
      }

      .closeText {
        word-break: keep-all;
        translate: 0 -1px;
      }
    }

  }

  .emptyKeyword {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;

    .emptyText {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 20px;
    }
  }

  .keywordContainer {
    padding: 20px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    min-height: 200px;

    .keyword {
      padding: 2px 12px;
      border-radius: 4px;
      background: #F1F5F9;
    }
  }
}