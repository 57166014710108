.pageContainer {
  min-height: 100%;
  background: #F1F5F9;

  .contentContainer {
    padding: 32px 28px 32px 40px;

    .topicContentContainer {
      padding: 52px 60px;
      margin-bottom: 24px;
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .headerLeft {
          display: flex;
          align-items: center;

          .infoContainer {
            display: flex;

            .infoItem {
              position: relative;
              padding: 0px 16px;
              font-size: 12px;
              line-height: 1.8;
              color: rgba(57, 60, 62, 0.4);

              &::after {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                width: 1px;
                height: 65%;
                background: rgba(57, 60, 62, 0.4);
              }
              &:first-child {
                padding-left: 0px;
              }

              &:last-child {
                &::after {
                  background: transparent;
                }
              }
            }
          }
        }
        .urlBtn {
          display: flex;
          align-items: center;
          color: #0367A5;
          transition: opacity 0.2s linear;
          .urlIcon {
            margin-right: 8px;
          }
          &:hover {
            opacity: 0.6;
          }
        }
      }

      .vocTitle {
        font-size: 18px;
        line-height: 1.8;
        font-weight: 700;
        transition: color 0.2s linear;
        cursor: pointer;
        margin-bottom: 4px;

        &:hover {
          color: #0367A5;
        }
      }

      .vocInfo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .infoItem {
          position: relative;
          padding: 0px 4px;
          font-size: 12px;
          line-height: 1.8;
          font-weight: 400;

          &::after {
            position: absolute;
            top: 50%;
            right: -0.5px;
            transform: translateY(-45%);
            content: '';
            height: 60%;
            width: 1px;
            background-color: #393C3E;
          }

          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            &::after {
              width: 0px;
            }
          }
        }
      }

      .content {
        font-size: 14px;
        color: rgba(57, 60, 62, 0.6);
        line-height: 1.8;
        text-overflow: ellipsis;
        margin-bottom: 20px;
      }

      .hitContainer {
        background: #F1F5F9;
        padding: 40px;

        .hitHeader {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .hitHeaderItem {
            position: relative;
            font-size: 16px;
            line-height: 1;
            font-weight: 700;
            padding: 0px 12px;

            &::after {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);

              content: '';
              width: 1px;
              height: 125%;
              background: #A7ABAE;
            }

            &:first-child {
              padding: 0px 12px 0px 0px;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }

        .commonHitContainer {
          .commonHitItem {
            font-size: 14px;
            line-height: 1.8;
            font-weight: 400;
          }
        }
      }
    }

    .commentsContainer {
      padding: 52px 60px;
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      .statisticsContainer {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #E4E6E7;
        
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.8;
        }
        .statisticSentimentsContainer {
          display: flex;
          align-items: center;
          .sentimentItem {
            margin-right: 28px;
            
            display: flex;
            align-items: center;
            .positiveDot {
              width: 10.5px;
              height: 10.5px;
              border-radius: 50%;
              background: #33A99B;

              margin-right: 8px;
            }
            .neutralDot {
              width: 10.5px;
              height: 10.5px;
              border-radius: 50%;
              background: #E2BD00;

              margin-right: 8px;
            }
            .negativeDot {
              width: 10.5px;
              height: 10.5px;
              border-radius: 50%;
              background: #BD4215;

              margin-right: 8px;
            }
            .staText {

            }
          }
        }
      }
      .changeSentimentContainer {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .changeTitle {
          margin-right: 8px;
        }
        .select {
          margin-right: 20px;
        }
        .submitBtn {
          width: 120px;
          height: 28px;
          padding: unset;
        }
      }
      .tableContainer {
        border: 1px solid #E4E6E7;
        border-radius: 8px 8px 0px 0px;
  
        .columns {
          white-space: pre-wrap;
          text-align: center;
          font-size: 12px;
          background: #393C3E;
          color: #fff;
          padding: 16px 8px;
  
          &::before {
            display: none;
          }
         .dotContainer {
          display: flex;
          align-items: center;
          justify-content: center;
           .sentimentDot {
             width: 10.5px;
             height: 10.5px;
             border-radius: 50%;
             &.positiveDot {
               background: #33A99B;
             }
             &.neutralDot {
               background: #E2BD00;
             }
             &.negativeDot {
               background: #BD4215;
             }
           }
         }
        }
  
        .row {
          position: relative;
  
          .columns {
            background: unset;
            color: unset;
  
            &::before {
              display: none;
            }
  
            .actionBtn {
              color: #393C3E;
  
              &:hover {
                color: #0367A5;
              }
            }
          }
  
          &:nth-child(odd) {
            background: #fff;
          }
  
          &:nth-child(even) {
            background: #F1F5F9;
          }
        }
      }
    }
  }
}

.hitTooltip {
  padding-left: 6px;
  .hitHeader {
    display: flex;
    align-items: center;

    .hitHeaderItem {
      position: relative;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      padding: 0px 12px;
      white-space: nowrap;

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        content: '';
        width: 1px;
        height: 50%;
        background: #A7ABAE;
      }

      &:first-child {
        padding: 0px 12px 0px 0px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .commonHitContainer {
    .commonHitItem {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}