.chartItem {
  position: relative;
  min-height: 400px;
  background: #FFFFFF;

  border: 1px dashed #A7ABAE;
  border-radius: 8px;
  margin-bottom: 20px;


  .chartTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 13px 0px 20px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.8;
    }

    .actions {
      display: flex;
      align-items: center;
      column-gap: 4px;

      &.displayNone {
        display: none;
      }

      .filterSelect {
        margin-right: 8px;
      }
    }
  }

  .timeStampContainer {
    padding: 0px 20px;
  }

  .chartContainer {
    padding: 0px 13px 20px 20px;
  }
  .curtain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .color {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }

    .absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}