.pageContainer {
  .settingContainer {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .timeSettingContainer {
      padding: 20px 24px;
      border-bottom: 1px solid #E4E6E7;
      display: flex;
      align-items: center;

      .settingName {
        font-size: 14px;
        line-height: 1.6;
        font-weight: 400;
        margin-right: 12px;
      }
    }

    .crossSettingContainer {
      padding: 20px 24px;

      .suggestContainer {
        padding-bottom: 20px;
        margin-bottom: 16px;
        border-bottom: 1px dashed #E4E6E7;

        .suggestContent {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .suggestTitle {
            font-size: 14px;
            line-height: 1.6;
            font-weight: 400;
            margin-right: 12px;
          }

          .suggestBtn {
            padding: 8px 16px;
            height: unset;
            margin-right: 12px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .suggestNote {
          font-size: 12px;
          line-height: 1;
          font-weight: 400;
          color: #A7ABAE;
        }
      }


      .settingContent {
        padding: 0px 0px 16px;

        .settingTitle {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.8;
          margin-bottom: 20px;
        }

        .optionRow {
          margin-bottom: 20px;
          display: grid;
          grid-template-columns: 240px 1fr;
          grid-gap: 24px;

          &:last-child {
            margin-bottom: 0px;
          }

          .select {
            font-size: 14px;

          }

          .paramsContainer {
            background: #FFFFFF;

            border: 1px solid #A7ABAE;

            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 8px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 8px;
            align-items: center;
            padding: 0px 16px;

            cursor: pointer;

            .paramsText {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              padding: 6px 0px;
              line-height: 2;
            }
          }

        }

        .btnsContainer {
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 24px;
          align-items: center;
          justify-content: center;

          .actionsBtn {
            width: 160px;
            height: 44px;
          }
        }

      }
    }
  }

  .resContainer {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 24px;

    .titleContainer {
      padding: 18px 28px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;

      border-bottom: 1px solid #E4E6E7;
    }

    .statisticFilter {
      padding: 20px 28px 0px;
      display: flex;
      column-gap: 8px;
      align-items: center;

      .btn {
        margin-left: 4px;
        width: 120px;

        &.submitBtn {
          color: #0367A5;
          border-color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }

        &.cleanBtn {
          color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .chartHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 28px;
      .chartTitle {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.8;
        .tooltip {
          margin-left: 8px;
          color: #0367A5;

          .tooltipText {
            white-space: nowrap;
            width: unset;
          }
        }
      }

      .buttonsContainer {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 8px;
        &.displayNone {
          display: none;
        }

        .saveBtn {
          position: relative;
          margin-right: 8px;
          &::after {
            content: '';
            width: 1px;
            height: 16px;
            background-color: #A7ABAE;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .active {
          border-color: #0367A5;
          background: #0367A5;
          display: flex;
          align-items: center;
          justify-content: center;

          .line {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/lineWhite.svg');
          }

          .bar {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/barWhite.svg');
            transform: rotate(90deg);
          }

          .pie {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/pieWhite.svg');
          }

          .compare {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/barWhite.svg');
          }
        }

        .inactive {
          display: flex;
          align-items: center;
          justify-content: center;

          .line {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/line.svg');
          }

          .bar {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/bar.svg');
            transform: rotate(90deg);
          }

          .pie {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/pie.svg');
          }

          .compare {
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('../../assets/chartType/bar.svg');
          }

          &:hover {
            border-color: #0367A5;
            background: #0367A5;

            .line {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../assets/chartType/lineWhite.svg');
            }

            .bar {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../assets/chartType/barWhite.svg');
              transform: rotate(90deg);
            }

            .pie {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../assets/chartType/pieWhite.svg');
            }

            .compare {
              width: 16px;
              height: 16px;
              background-size: contain;
              background-image: url('../../assets/chartType/barWhite.svg');
            }
          }
        }
      }
    }
  }

}

.compare {
  transform: rotate(-90deg) rotateX(180deg);
}