.downloadModalContainer {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .downloadModalTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    position: relative;
    width: fit-content;
    
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 105%;
      height: 2px;
      background-color: #0367A5;
      bottom: -2px;
      left: 50%;
      translate: -50% 0;
    }
  }

  .downloadModalContent {
    white-space: pre-wrap;
  }

  .downloadModalActions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;

    .downloadBtn {
      width: 120px;

      transition: all 0.3s ease;

      &:hover {
        color: #0367A5;
        border-color: #0367A5;
      }

      &.downloadBtnPrimary {
        background-color: #0367A5;
        border-color: #0367A5;

        &:hover {
          color: #fff;
          opacity: 0.8;
        }
      }
    }

  }
}