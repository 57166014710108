.container {
  padding: 28px 20px;
  .scatterContainer {
    display: flex;
    width: 100%;
    aspect-ratio: 3;
  }

  .collapseContainer {
    margin-top: 40px;
    font-size: 16px;

    .tableContainer {

      margin: 20px 0px;
      border: 1px solid #E4E6E7;
      border-radius: 8px 8px 0px 0px;

      .headerRow {
        padding: 11px 0px;
        font-size: 12px;
        line-height: 1.8;
        font-weight: 400;
      }

      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        background: #393C3E;
        color: #fff;
        padding: 12px 8px;

        &::before {
          display: none;
        }
      }

      .row {
        position: relative;

        .columns {
          background: unset;
          color: unset;

          &::before {
            display: none;
          }

          .actionBtn {
            width: unset;
            height: unset;
            color: #393C3E;
            margin-right: 6px;

            &:last-child {
              margin-right: 0px;
            }

            &:hover {
              color: #0367A5;
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }

        &:nth-child(even) {
          background: #F1F5F9;
        }
      }
    }
  }
}