.pageContainer {
  .pageHeader {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .pageTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 180%;
      padding: 18px 28px;
      border-bottom: 1px solid #E4E6E7;
    }

    .statisticFilter {
      padding: 20px 0px;
      margin: 0px 28px;
      display: flex;
      column-gap: 8px;
      align-items: center;
      &:nth-child(2n + 1) {
        border-top: 1px dashed #CBCBCB;
      }

      .btn {
        margin-left: 4px;
        width: 120px;

        &.submitBtn {
          color: #0367A5;
          border-color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }

        &.cleanBtn {
          color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .resContainer {
    margin-top: 24px;

    .typeSegment {
      display: flex;

      .segmentItem {
        padding: 16px 28px;
        background: rgba(57, 60, 62, 0.04);
        border: 1px solid #E4E6E7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(57, 60, 62, 0.6);
        transition: background 0.2s linear, color 0.2s linear;

        &:last-child {
          border-radius: 0px 8px 0px 0px;
        }

        &:first-child {
          border-radius: 8px 0px 0px 0px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.6);
          color: rgba(3, 103, 165, 0.6);
        }

        &.active {
          position: relative;
          color: #0367A5;
          background: #fff;

          &::after {
            content: '';
            width: 100%;
            height: 4px;

            background: #fff;

            position: absolute;
            bottom: -2px;
            left: 0;

          }
        }
      }
    }

    .chartContainer {
      padding: 20px 0px;
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 0px 8px 8px 8px;

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 24px 20px;
        padding-bottom: 20px;
       
        &.border {
          border-bottom: 1px dashed #CBCBCB;
        }

        .chartFilter {
          display: flex;
          column-gap: 8px;
          align-items: center;

          .filterItem {
            display: flex;
            column-gap: 8px;
            align-items: center;

            .itemTitle {
              font-size: 12px;
            }
          }

          .btn {
            margin-left: 4px;
            width: 120px;

            &.submitBtn {
              color: #0367A5;
              border-color: #0367A5;
              transition: opacity 0.2s linear;

              &:hover {
                opacity: 0.6;
              }
            }

            &.cleanBtn {
              color: #0367A5;
              transition: opacity 0.2s linear;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 4px;
        }
      }
      .radioContainer {
        margin-bottom: 20px;
        .levelsRadio {
          label {
            height: unset;
            padding: 2.5px 16px;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}


.blockModal {
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    border-bottom: 1px solid #E4E6E7;
    .modalTitle {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .modalText {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .tableContainer {
    padding: 20px;
    height: 450px;
    overflow: scroll;

    .headerRow {
      padding: 11px 0px;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
    }

    .columns {
      white-space: pre-wrap;
      text-align: center;
      font-size: 12px;
      background: #393C3E;
      color: #fff;
      padding: 12px 8px;

      &::before {
        display: none;
      }
    }

    .row {
      position: relative;

      .columns {
        background: unset;
        color: unset;

        &::before {
          display: none;
        }

        .actionBtn {
          width: unset;
          height: unset;
          color: #393C3E;
          margin-right: 6px;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: #0367A5;
          }
        }
      }

      &:nth-child(odd) {
        background: #fff;
      }

      &:nth-child(even) {
        background: #F1F5F9;
      }
    }
  }
}