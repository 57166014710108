.pageContainer {
  position: relative;
  height: 100vh;
  background: linear-gradient(180deg, #083D5E 40.1%, #165A84 100%);
  overflow: hidden;
  
  .bg {
    position: absolute;
    top: 35px;
    right: 0;
  }
  .pic {
    position: absolute;
    top: 20.68%;
    right: 13.68%;
  }
  .loginContainer {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 42px;
      color: #fff;
      font-weight: 700;
      line-height: 1.2;
      white-space: pre-wrap;
      margin-bottom: 40px;
    }
    .content {
      font-size: 20px;
      font-weight: 400;
      line-height: 2;
      color: #fff;
      white-space: pre-wrap;
      margin-bottom: 80px;
    }
    .btn {
      width: 160px;
      height: 44px;
      line-height: 36px;
      background-color: #45C0B2;
      border: none;
      color: #fff;
      &:hover {
        color: #fff;
        background-color: #32ddc9;
      }
    }
  }
}