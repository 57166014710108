.mainContainer {

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #393C3E;

    .leftHeader {
      display: flex;
      align-items: center;

      .logoContainer {
        display: flex;
        align-items: center;

        img {
          width: 150px;
        }
      }

      .accountSwitchContainer {
        position: relative;
        margin: 0px 12px 0px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 24px;
        color: rgba(255, 255, 255, 0.45);

        &::before {
          content: '';
          width: 2px;
          height: 20px;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        .activeAccount {
          display: flex;
          align-items: center;
          color: #fff;
          cursor: pointer;

          .text {
            margin-right: 12px;
          }
        }
      }

      .packagesBtn {
        height: 32px;
        background: #0367A5;
        transition: background 0.2s linear;

        &:hover {
          background: rgb(60, 122, 165);
        }
      }
    }

    .rightHeader {
      display: flex;
      align-items: center;

      .userMenuContainer {
        display: flex;
        cursor: pointer;

        .userIcon {
          padding-right: 5px;
        }

        .dropdownIcon {
          padding-right: 10px;
        }
      }

      .searchContainer {
        display: grid;
        grid-template-columns: 1fr 20px;
        align-items: center;
        justify-content: center;
        margin-right: 35px;
        width: 300px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 0px 32px;
        border-radius: 48px;

        .searchInput {
          width: 100%;
          height: 40px;
          background-color: transparent;
          color: #fff;
          font-size: 14px;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
            font-weight: 400;
          }
        }

        .searchBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            transform: translateY(1px);
          }
        }
      }
    }
  }

  .siderContainer {
    width: 240px;
    background: #fff;
    height: 100%;
    overflow: auto;
  }

  .contentContainer {
    background-color: #F1F5F9;
    padding: 32px 28px 32px 40px;
    overflow: auto;

    .update {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.popoverContainer {
  .userInfo {
    padding: 12px 32px 0px;
    line-height: 1.8;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Noto Sans Tc', sans-serif;
    color: #393C3E;
  }

  .userValidity {
    padding: 8px 32px 0px;
    line-height: 1.8;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Noto Sans Tc', sans-serif;
    color: #A7ABAE;
  }

  .actions {
    display: block;
    padding: 12px 32px 0px;
    font-family: 'Noto Sans Tc', sans-serif;
    transition: opacity 0.2s linear;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      padding-bottom: 12px;
    }
  }

  .divider {
    margin-top: 12px;
    height: 1px;
    background-color: #E4E6E7;
  }
}

.searchDrawerContainer {
  .container {
    padding: 32px 28px 32px 40px;
    height: 100%;
    overflow: auto;

    .searchHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .headerItem {
        display: grid;
        grid-template-columns: repeat(3, auto) 1fr;
        grid-gap: 20px;
        align-items: center;

        .searchTitle {
          font-size: 20px;
          line-height: 1.2;
          font-weight: 700;
          color: #0367A5;
        }
      }
    }

    .searchSettingContainer {
      background-color: #fff;
      min-height: 150px;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      display: grid;
      grid-template-rows: 8fr 7fr;

      margin-bottom: 24px;

      .inputContainer {
        border-bottom: 1px solid #E4E6E7;
        margin-left: 28px;
        display: flex;
        align-items: center;

        .searchTitle {
          margin-right: 24px;
        }

        .searchContainer {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: center;
          justify-content: center;
          margin-right: 35px;
          width: 300px;
          height: 40px;
          background-color: rgba(167, 171, 174, 0.2);
          padding: 0px 32px;
          border-radius: 48px;

          .searchInput {
            width: 100%;
            height: 40px;
            background-color: transparent;
            color: rgb(57, 60, 62);
            font-size: 14px;

            &::placeholder {
              color: rgba(57, 60, 62, 0.4);
              font-weight: 400;
            }
          }

          .searchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              transform: translateY(1px);
            }
          }
        }
      }

      .filterContainer {
        margin-left: 28px;
        display: flex;
        align-items: center;

        .filterTitleContainer {
          display: flex;
          align-items: center;
          justify-content: center;

          .filterTitle {
            margin-right: 8px;
            color: #0367A5;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
          }

          .filterIcon {
            margin-right: 8px;
          }

          .filterSelect {
            margin-right: 8px;
          }

          .submitBtn {
            width: 120px;
            margin-right: 8px;
            color: #0367A5;
            border-color: #0367A5;

            // transition: opacity 0.2s linear;
            &:hover {
              opacity: 0.6;
            }
          }

          .cleanAllBtn {
            width: 120px;
            border: none;
            box-shadow: none;
            color: #0367A5;

            // transition: opacity 0.2s linear;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }

    .searchResContainer {
      .searchResHeader {
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #E4E6E7;
        margin-bottom: 16px;

        .deprecateTitle {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.8;
          margin-left: 28px;
          margin-bottom: 20px;
        }

        .searchResTitle {
          position: relative;

          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          padding: 18px 28px 14px;
          border: 1px solid #E4E6E7;
          border-bottom: none;
          border-right: none;
          color: #393C3E;
          background: rgba(57, 60, 62, 0.04);

          cursor: pointer;

          transition: color .2s linear, background .2s linear;

          &:hover {
            color: #0367A5;
            background: #fff;
          }

          &.active {
            color: #0367A5;
            background: #fff;
            &::after {
              content: '';
              position: absolute;
              bottom: -1px;
              left: 0;
              height: 1px;
              width: 100%;
              background: #fff;
            }
          }

          &:first-child {
            border-radius: 8px 0px 0px 0px;
          }

          &:last-child {
            border-radius: 0px 8px 0px 0px;
            border-right: 1px solid #E4E6E7;
          }
        }
      }

      .dotDivider {
        margin: 20px 0px;
        border-bottom: 1px dashed #CBCBCB;
      }

      .sortContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 28px;

        .titleAndSortContainer {
          display: flex;
          align-items: center;

          .resTitle {
            margin-right: 8px;
          }

          .sortSelect {
            line-height: 1;
          }
        }

        .downloadButton {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .resContainer {
        margin-top: 4px;
      }
    }
  }


  .customizeBack {
    position: absolute;
    top: 0;
    left: -44px;
    width: 44px;
    height: 52px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 0px 0px 4px;

    cursor: pointer;
  }
}

.tooltip {
  white-space: nowrap;
  max-width: none;
}

.switchAccountPopover {
  width: 327px;
  height: 350px;
  padding: 8px 0px;

  display: grid;
  grid-template-rows: auto 1fr;

  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    padding: 8px 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 16px;

    border-bottom: 1px solid #E4E6E7;

    .searchText {
      font-size: 14px;

    }

    .searchContainer {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      justify-content: center;
      // margin-right: 35px;
      width: 100%;
      background-color: rgba(167, 171, 174, 0.2);
      padding: 0px 32px;
      border-radius: 48px;

      .searchInput {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: #393C3E;
        font-size: 14px;

        &::placeholder {
          color: rgba(57, 60, 62, 0.4);
          font-weight: 400;
        }
      }

      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          transform: translateY(1px);
        }
      }
    }
  }

  .customerList {
    height: 100%;
    overflow: auto;

    .customerCard {
      padding: 16px 12px;
      cursor: pointer;

      &:hover {
        background: #F5F5F5;
      }

      .name {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 8px;

        .teamName {
          color: #858a8f;
        }
      }

      .account {
        font-size: 10px;
        line-height: 1;
        font-weight: 400;
        color: #A7ABAE;
      }

      &.isActive {
        background: #E6F7FF;

        .name {
          font-weight: 700;
        }
      }
    }
  }
}


.resEmptyContainer {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchDateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.curtain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999999999;

  display: flex;
  align-items: center;
  justify-content: center;

  .loadingContainer {
    background: #fff;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .note {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.8;
      padding: 0px 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    .rejectBtn {
      width: 160px;
      height: 40px;
      margin-top: 16px;
      margin-bottom: 24px;
      background: #0367A5;
      color: #fff;

      &:hover {
        color: #fff;
        opacity: 0.9;
      }
    }
  }
}