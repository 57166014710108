.switchProjectPopover {
  width: 327px;
  height: 350px;

  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selector {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  background: #f5f5f5;
  padding: 0px 11px;
  border-radius: 6px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  
  &.inactive {
    cursor: not-allowed;
  }

  .text {
    line-height: 28px;
    &.active {
      color: #393C3E;
    }
  }
}

.selectCard {
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #E4E6E7;
  border-radius: 8px;
  .cardHeader {
    padding: 20px 16px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cardSelectAll {
      display: flex;
      align-items: center;
      justify-content: center;
      .selectAllText {
        margin-left: 8px;
      }
    }
  }
  .cardContent {
    padding: 16px;
    .searchInputContainer {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 40px;
      background-color: rgba(167, 171, 174, 0.2);
      padding: 0px 32px;
      border-radius: 48px;

      .searchInput {
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: rgb(57, 60, 62);
        font-size: 14px;

        &::placeholder {
          color: rgba(57, 60, 62, 0.4);
          font-weight: 400;
        }
      }

      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          transform: translateY(1px);
        }
      }
    }

    .levelCheckboxContainer {
      height: 200px;
      overflow: scroll;
      margin: 12px 12px 0px;
    }
  }
}