.container {
  .animationContainer {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions {
    padding: 0px 24px;
    .radioContainer {
      overflow: auto;
      .radioGroup {
        width: max-content;
        margin-bottom: 20px;
        label {
          padding: 8px 16px;
          height: unset;
          line-height: 1.6;
          font-size: 14px;
        }
      }
    }
  }
  .downloadContainer {
    padding: 0px 24px;
    .chartTitle {
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.8;
      .tooltip {
        margin-left: 8px;
        color: #0367A5;
        &.displayNone {
          display: none;
        }
  
        .tooltipText {
          white-space: nowrap;
          width: unset;
        }
      }
    } 
  }
}


.modal {
  .modalHeader {
    width: 100%;
    padding: 0px 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      color: #0367A5;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .btnContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    .btn {
      width: 120px;
      height: 44px;
      &.normalBtn {
        border-color: #0367A5;
        color: #0367A5;
        &:hover {
          opacity: 0.8;
        }
      }
      &.blockBtn {
        &:hover {
          color: #393C3E;
          border-color: #d9d9d9;
        }
      }
      
    }
  }
}