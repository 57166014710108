.drawerContainer {
  .customizeBack {
    position: absolute;
    top: 0;
    left: -44px;
    width: 44px;
    height: 52px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0px 0px 0px 4px;

    cursor: pointer;
  }

  .contentContainer {
    height: 100%;
    overflow: scroll;
    padding: 0px 28px 20px 40px;

    .contentHeader {
      display: grid;
      grid-template-columns: repeat(3, auto) 1fr;
      grid-gap: 10px;
      align-items: center;
      padding: 32px 0px;

      .headerTitle {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        color: #0367A5;
      }
    }

    .section {
      .divider {
        width: 100%;
        height: 1px;
        background-color: #E4E6E7;
        margin: 12px 0px 24px;
      }

      .sectionHeaderContainer {
        display: grid;
        grid-template-columns: repeat(2, auto) 1fr;
        grid-gap: 17px;
        align-items: center;
        margin: 10px 0px 30px;

        .sectionHeader {
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
        }
      }


      .row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 28px;

        align-items: center;

        &.radioRow {
          margin: 12px 0px;

          .radioGroup {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .radioItem {
              display: grid;
              grid-template-columns: repeat(2, auto);
              grid-gap: 4px;
              align-items: center;


              .radio {
                appearance: none;

                position: relative;

                width: 16px;
                height: 16px;
                border: 1px solid #393C3E;
                border-radius: 50%;

                &::after {
                  opacity: 0;
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: #0367A5;

                  transform: translate(-50%, -50%);
                  transition: opacity .1s linear;
                }

                // color: #0367A5;
                &:checked {
                  border-color: #0367A5;
                  background-color: #fff;

                  &::after {
                    opacity: 1;
                  }

                }
              }

              .radioName {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.8;
              }
            }
          }
        }

        .rowTitle {
          text-align: right;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 400;
          margin-bottom: 20px;

          &.errorTitle {
            color: #D34C1A;
          }
        }

        .rowContent {
          width: 100%;

          .rowInput {
            width: 100%;
            height: 44px;
            margin-bottom: 20px;

            &.errorInput {
              border-color: #D34C1A;
              margin-bottom: 0px;
            }
          }

          .rowPicker {
            width: 100%;
            height: 44px;
            margin-bottom: 20px;

            &.errorPicker {
              margin-bottom: 0px;
              border-color: #D34C1A;
            }
          }

          .switch {
            margin-bottom: 20px;
          }

          .rowSelect {
            height: 44px;
            width: 100%;
            margin-bottom: 20px;

            &.errorSelect {
              margin-bottom: 0px;

              div {
                border-color: #D34C1A;
              }
            }

            &.single {
              div {
                height: 44px;

                span {
                  line-height: 44px;
                }
              }
            }

            &.multi {
              div {
                height: 44px;

                input {
                  height: 44px;
                }

                span {
                  line-height: 36px;
                  height: 36px;
                }
              }
            }
          }

          .errorNote {
            font-size: 12px;
            padding: 4px 0px;
            line-height: 1;
            color: #D34C1A;
          }

          .todayFrequencyRow {
            display: flex;
            align-items: center;
            column-gap: 8px;
            margin-bottom: 20px;
            .todayFrequencyItem {
              display: flex;
              align-items: center;
              column-gap: 8px;
              .todayFrequencyName {
                font-size: 16px;
                line-height: 1.2;
                font-weight: 400;
              }
            }
          }

          .errorTodayRow {
            margin-bottom: 0px;
            .todayFrequencyItem {
              .todayFrequencyName {
                
              }
            }
          }
        }

        .emailInputContainer {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 12px;

          .emailAddBtn {
            padding: 0;
            width: 78px;
            height: 44px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            color: #0367A5;
            border-color: #0367A5;
            transition: opacity 0.2s linear;
            align-items: center;

            span {
              &:first-child {
                font-size: 12px;
              }

              &:last-child {
                margin-inline-start: 6px;
              }
            }

            &:hover {
              opacity: 0.6;
            }
          }
        }

        &.selectedReceiverRow {
          align-items: start;

          .rowTitle {
            padding: 12px 0px;
          }

          .selectedReceiver {
            width: 100%;
            height: max-content;
            min-height: 120px;
            background: #FFFFFF;
            border: 1px solid #A7ABAE;
            border-radius: 8px;
            padding: 12px 11px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 8px;

            .receiverPlaceholder {
              font-size: 16px;
              line-height: 1.2;
              font-weight: 400;
              color: #A7ABAE;
            }

            .receiverCard {
              width: min-content;
              padding: 7px 12px;
              display: grid;
              grid-template-columns: repeat(2, auto);
              grid-gap: 4px;
              align-items: center;
              background-color: #E4E6E7;
              border-radius: 4px;
            }
          }
        }
      }

      &.bulletinSection {
        display: flex;
        flex-direction: column;
        align-items: center;

        .addBtn {
          width: 160px;
          padding: 14px 0px;
          height: 44px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          color: #0367A5;
          border-color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .submitContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    border-top: 1px solid #E4E6E7;
    padding: 12px 0px;

    .btn {
      width: 160px;
      padding: 14px 0px;
      height: 44px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;

      &.submitBtn {
        color: #0367A5;
        border-color: #0367A5;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .sendBtn {
      button {
        width: 160px;
        padding: 14px 0px;
        height: 44px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
      }

      &.active {
        width: 160px;
        padding: 14px 0px;
        height: 44px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        background-color: #0367A5;
        color: #fff;
        transition: opacity 0.2s linear;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.deleteModal {
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .deleteText {
      font-size: 16px;
      line-height: 1.8;
      font-weight: 400;
      white-space: pre-wrap;
      margin-bottom: 20px;
      text-align: center;
    }

    .btnContainer {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(2, auto);
      grid-gap: 12px;

      .btn {
        width: 120px;
        height: 44px;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;

        &.confirm {
          color: #0367A5;
          border-color: #0367A5;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.6;
          }
        }

        &.cancel {
          &:hover {
            color: #0367A5;
            border-color: #0367A5;
          }
        }
      }
    }
  }

}