.modal {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;

  .modalHeader {
    padding: 20px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerTitle {
      display: flex;
      align-items: center;

      .titleIcon {
        margin-right: 18px;
      }

      .titleText {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
        .icon {
          color: #0367A5;
          padding-left: 8px;
        }
      }
    }

    .headerClose {
      color: #0367A5;
      transition: opacity 0.1s linear;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .modalContent {
    padding: 20px 44px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .selectCard {
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      border-radius: 12px;
      .cardHeader {
        padding: 20px 16px;
        border-bottom: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cardSelectAll {
          display: flex;
          align-items: center;
          justify-content: center;
          .selectAllText {
            margin-left: 8px;
          }
        }
      }
      .cardContent {
        padding: 16px;
        .searchInputContainer {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: center;
          justify-content: center;
          width: 300px;
          height: 40px;
          background-color: rgba(167, 171, 174, 0.2);
          padding: 0px 32px;
          border-radius: 48px;

          .searchInput {
            width: 100%;
            height: 40px;
            background-color: transparent;
            color: rgb(57, 60, 62);
            font-size: 14px;

            &::placeholder {
              color: rgba(57, 60, 62, 0.4);
              font-weight: 400;
            }
          }

          .searchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              transform: translateY(1px);
            }
          }
        }

        .levelCheckboxContainer {
          height: 200px;
          overflow: hidden;
          margin: 12px 12px 0px;
        }
      }
    }

  }

  .modalFooter {
    padding: 32px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btnContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;

      .btn {
        width: 160px;
        height: 44px;

        &.cleanBtn {
          &:hover {
            color: #0367A5;
            border-color: #0367A5;
          }
        }

        &.applyBtn {
          background: rgba(3, 103, 165, 1);

          &:hover {
            background: rgba(3, 103, 165, 0.8);
          }
        }
      }
    }

  }
}