.pageContainer {
  position: relative;
  .optionsContainer {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .levelSelectContainer {
      padding: 20px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .textContainer {
        display: flex;
        align-items: center;

        .text {
          position: relative;
          padding-right: 8px;
          margin-right: 8px;

          &::after {
            content: '';
            position: absolute;
            height: 70%;
            width: 1px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            background: #393C3E;
          }
        }

        .filterBtn {
          display: flex;
          align-items: center;
          padding: 0px;

          &:hover {
            opacity: 0.6;
          }

          .btnText {
            margin-right: 8px;
            color: #0367A5;
          }
        }
      }

      .icon {
        transition: transform 0.3s linear;

        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }

    .selectedItemsContainer {
      background: #FFFFFF;

      border-radius: 8px;
      padding: 20px 28px;

      .selectedRow {
        display: grid;
        grid-template-columns: auto 1fr;

        .selectedItem {
          font-size: 14px;
          line-height: 1.8;
          font-weight: 400;
        }
      }
    }
  }

  .resContainer {
    margin-top: 24px;

    .sourceSegment {
      display: flex;

      .segmentItem {
        padding: 16px 28px;
        background: rgba(57, 60, 62, 0.04);
        border: 1px solid #E4E6E7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(57, 60, 62, 0.6);
        transition: background 0.2s linear, color 0.2s linear;

        &:first-child {
          border-radius: 8px 0px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 8px 0px 0px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.6);
          color: rgba(3, 103, 165, 0.6);
        }

        &.active {
          position: relative;
          color: #0367A5;
          background: #fff;

          &::after {
            content: '';
            width: 100%;
            height: 4px;

            background: #fff;

            position: absolute;
            bottom: -2px;
            left: 0;

          }
        }
      }
    }

    .mainContainer {
      background: #FFFFFF;
      border: 1px solid #E4E6E7;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 0px 8px 8px 8px;

      .filterContainer {
        padding: 20px 0px;
        margin: 0px 28px;
        border-bottom: 1px dashed #CBCBCB;
        
        display: flex;
        align-items: center;
        .filterSelect {
          margin-right: 12px;
        }

        .submitBtn {
          width: 120px;
          margin-right: 8px;
          color: #0367A5;
          border-color: #0367A5;

          // transition: opacity 0.2s linear;
          &:hover {
            opacity: 0.6;
          }
        }

        .cleanAllBtn {
          width: 120px;
          border: none;
          box-shadow: none;
          color: #0367A5;

          // transition: opacity 0.2s linear;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .sortContainer {
        padding: 20px 28px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sorts {
          display: flex;
          align-items: center;
          .sortText {
            font-size: 14px;
            line-height: 1.6;
            font-weight: 400;
            margin-right: 8px;
          }
          .filterSelect {
            margin-right: 8px;
          }
        }
        .downloadButton {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .resContainer {
        padding: 0px 28px;  
        .emptyContainer {
          height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;
        }      
      }
    }
  }
}
.curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}