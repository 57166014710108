.chartItem {
  position: relative;
  min-height: 400px;
  background: #FFFFFF;

  border: 1px dashed #A7ABAE;
  border-radius: 8px;
  margin-bottom: 20px;

  .chartTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 13px 0px 20px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.8;
    }

    .actions {
      display: flex;
      align-items: center;
      column-gap: 4px;
      &.displayNone {
        display: none;
      }

      .filterSelect {
        margin-right: 8px;
      }
    }
  }

  .timeStampContainer {
    padding: 0px 20px;
  }

  .chartContainer {
    padding: 24px 13px 24px 20px;
    .container {
      position: relative;
      // min-width: 200px;
    
      .wordsContainer {
        position: relative;
        width: 100%;
        height: 300px;
    
        .wordItem {
          transition: 1.4s;
          cursor: pointer;
          position: relative;
          width: max-content !important;
          font-family: 'Noto sans TC' !important;
    
          .tooltip {
            position: absolute;
            bottom: -24px;
            left: 50%;
            transform: translateX(-50%);
            display: none;
            font-size: 12px;
            color: #393C3E;
            padding: 8px 16px;
            background: #FFFFFF;
            border: 2px solid #0367A5;
            border-radius: 4px;
            color: #0367A5;
            font-weight: 400;
          }
    
          .closeBtn {
            display: none;
            opacity: 0;
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 12px;
            padding: 9px;
            border-radius: 50%;
            color: #FFFFFF;
            background-color: #0367A5;
            transition: opacity .5s linear;
          }
    
          &:hover {
            z-index: 10000;
    
            .tooltip {
              display: block;
            }
    
            .closeBtn {
              display: block;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .collapseContainer {
    padding: 0px 13px 20px 20px;
  }
  .curtain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .color {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }

    .absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.collapseContainer {
  margin-top: 40px;
  font-size: 16px;

  .tableContainer {

    margin: 20px 0px;
    border: 1px solid #E4E6E7;
    border-radius: 8px 8px 0px 0px;

    .headerRow {
      padding: 11px 0px;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
    }

    .columns {
      white-space: pre-wrap;
      text-align: center;
      font-size: 12px;
      background: #393C3E;
      color: #fff;
      padding: 12px 8px;

      &::before {
        display: none;
      }
    }

    .row {
      position: relative;

      .columns {
        background: unset;
        color: unset;

        &::before {
          display: none;
        }

        .actionBtn {
          width: unset;
          height: unset;
          color: #393C3E;
          margin-right: 6px;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: #0367A5;
          }
        }
      }

      &:nth-child(odd) {
        background: #fff;
      }

      &:nth-child(even) {
        background: #F1F5F9;
      }
    }
  }
}