.chartContainer {
  border: 1px dashed #A7ABAE;
  border-radius: 8px;

  .download {
    padding: 20px;
  }

  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    .headerTitle {
      margin-left: 16px;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.8;
    }
    .downloadActions {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }

  .timeStamp {
    padding-left: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}