.pageContainer {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  .pageTitleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .titlePrefix {
      height: 30px;
      width: 12px;
      background: #0367A5;
      margin-right: 16px;
    }
    .pageTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      color: #0367A5;
    }
  }

  .tableContainer {
    background-color: #fff;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-bottom: 20px;
    overflow: hidden;
    
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2, auto) 1fr;
    .filterContainer {
      padding: 20px 0px 20px 28px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E4E6E7;
      overflow: auto;
      .filterTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        .filterTitle {
          margin-right: 8px;
          color: #0367A5;
          font-weight: 400;
          font-size: 14px;
          line-height: 1;
        }
        .filterIcon {
          margin-right: 16px;
        }
        .filterSelect {
          margin-right: 16px;
        }
        .submitBtn {
          width: 120px;
          margin-right: 16px;
          color: #0367A5;
          border-color: #0367A5;
          &:hover {
            opacity: 0.6;
          }
        }
        .cleanAllBtn {
          width: 120px;
          border: none;
          box-shadow: none;
          color: #0367A5;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
    .actionsContainer {
      padding: 20px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .addCustomerBtn {
        color: #0367A5;
        border-color: #0367A5;
        &:hover {
          opacity: 0.6;
        }
      }
      .searchContainer {
        display: grid;
        grid-template-columns: 1fr 20px;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 40px;
        background-color: rgba(167,171,174,0.2);
        padding: 0px 32px;
        border-radius: 48px;
        .searchInput {
          width: 100%;
          height: 40px;
          background-color: transparent;
          color: rgb(57,60,62);
          font-size: 14px;
          &::placeholder {
            color: rgba(57,60,62, 0.4);
            font-weight: 400;
          }
        }
        .searchBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            transform: translateY(1px);
          }
        }
      }
    }
    .resContainer {
      height: 100%;
      overflow: hidden;
      margin: 0px 28px;
      border: 1px solid #E4E6E7;
      .columns {
        white-space: pre-wrap;
        text-align: center;
        font-size: 12px;
        border-radius: 0px !important;
        background: #393C3E;
        color: #fff;
        padding: 6px 8px;
        &::before {
          display: none;
        }
      }
      .row {
        position: relative;
        .columns {
          background: unset;
          color: unset;
          &::before {
            display: none;
          }
          .actionBtn {
            color: #393C3E;
            &:hover {
              color: #0367A5;
            }
          }
        }
        &:nth-child(odd) {
          background: #fff;
        }
        &:nth-child(even) {
          background: #F1F5F9;
        }
      }
    }
  }
}

.actionNote {
  font-size: 12px;
}

.customerDepTooltip {
  color: #0367A5;
  text-decoration: underline;
}