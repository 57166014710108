.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #393C3E !important;

  .logoContainer {
    display: flex;
    align-items: center;

    img {
      width: 150px;
    }
  }
}