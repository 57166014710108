.emptyContainer {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartSectionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
      margin-left: 33px;
    }
    .actionBtns {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .btn {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .summaryContainer {
    display: grid;
    grid-template-columns: repeat(7, 2fr) 5fr;
    border: 1px solid #E4E6E7;
    border-radius: 8px;
    overflow: hidden;
    .head {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
      text-align: center;
      padding: 17px 0px;

      background: #393C3E;
      color: #fff;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 1.8;
      font-weight: 400;
      text-align: center;
      padding: 17px 0px;

      color: #393C3E;

      &.empty {
        color: #D34C1A;
        font-weight: 700;
      }

      .link {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #0367A5;
      }
    }
  }
}