.container {
  position: relative;
  background-color: #fff;
  .siderContainer {
    height: 100%;
    overflow: auto;
    background: #fff;
  }
  .trigger {
    position: relative;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;

    position: absolute;
    top: 12px;
    right: -9px;

    cursor: pointer;
    .icon { 
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 9px;
    }
  }
}

